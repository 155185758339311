.menu-active {
	color: #ea3661 !important;
}

/* width */
::-webkit-scrollbar {
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ffffff00;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ffffff00;
}

.swal2-container {
	z-index: 1200 !important;
}

.ReactRibbons_rightCornerRibbonText_lAUhrhctW69RCvQMzXCAj {
	top: -10px;
	right: -10px;
}
